<template>
  <div v-if="events.length">
    <event-detail
      v-for="(event, index) in events"
      :event="event"
      :key="index"
      :theme="getThemeColor(index)"
      :type="type"
      :user-roles="userRoles"
    />
  </div>
  <div v-else>
    <p class="body-1 text-center px-2">
      {{ $t('dashboard.noEvent') }}
    </p>
  </div>
</template>

<script>
import LayoutUtil from '@/helpers/layout/layout.helper';
import EventDetail from '@/components/event-detail/EventDetail.vue';

export default {
  name: 'EventList',
  props: ['events', 'type', 'userRoles'],
  components: {
    EventDetail,
  },
  methods: {
    getThemeColor(index) {
      return LayoutUtil.getThemeColor(index);
    },
  },
};
</script>

<style scoped></style>
