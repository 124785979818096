<template>
  <div v-if="myEventsAreLoaded" id="dashboard">
    <div class="dashboard__title">{{ $t('dashboard.my-events') }}</div>

    <event-list v-if="myEvents" :events="myEvents" type="user" :user-roles="loggedInUserRoles" />

    <template v-if="hasFilteredEvents">
      <div class="dashboard__title">
        {{ $t('dashboard.other-events') }}
      </div>

      <event-list :events="filteredEvents" type="all" />
    </template>
  </div>

  <loading v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import EventList from '@/components/event-list/EventList.vue';
import Loading from '@/components/loading/Loading.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  FETCH_ALL_EVENTS,
  FETCH_MY_EVENTS,
} from '@/stores/agnostic/actions/event/agnostic-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'Dashboard',
  components: {
    EventList,
    Loading,
  },
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['events', 'myEvents', 'myEventsAreLoaded']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    filteredEvents() {
      return this.events.filter(
        (event) => !this.myEvents.find((myEvent) => myEvent.id === event.id),
      );
    },
    hasFilteredEvents() {
      return this.filteredEvents?.length > 0;
    },
  },
  methods: {
    ...mapActions(APP_EVENT_MODULE, [FETCH_ALL_EVENTS, FETCH_MY_EVENTS]),
    async initialize() {
      await this[FETCH_MY_EVENTS]({ endDate: DateTime.now().toISO() });
      this[FETCH_ALL_EVENTS]();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/core/variables';

#dashboard {
  height: 100%;
  width: 100%;
}

.dashboard {
  &__title {
    padding: 1rem;
    font-size: $xxx-large-font-size;
  }
}
</style>
