<template>
  <div>
    <v-sheet :dark="theme === 'dark'" class="py-4 py-lg-16">
      <v-container fluid class="px-0 py-0">
        <v-row no-gutters>
          <v-col cols="12" lg="7">
            <h2 class="name hidden-lg-and-up px-8">{{ event.name }}</h2>
            <v-carousel
              :show-arrows="hasMoreThanOneCoverImages"
              :hide-delimiters="!hasMoreThanOneCoverImages"
            >
              <v-carousel-item
                v-for="(item, index) in event.coverImages"
                :key="index"
                :src="item"
              />
            </v-carousel>
          </v-col>
          <v-col cols="12" lg="4" class="pl-lg-16">
            <h2 class="name hidden-md-and-down">{{ event.name }}</h2>
            <h3 class="date px-8 px-lg-0">
              {{
                $t('dashboard.open-at', {
                  date: $d(Date.parse(event.startDate), 'longDate'),
                  time: $d(Date.parse(event.startDate), 'shortTime'),
                })
              }}
            </h3>
            <p class="description pt-2 px-8 px-lg-0">
              <read-more
                :less="$t('globals.readLess')"
                :more="$t('globals.readMore')"
                :text="event.description"
                v-if="event.description"
              />
            </p>
            <div v-if="type === 'user'" class="d-flex flex-column align-center">
              <v-btn
                data-test-id="go-to-lobby"
                :to="`/events/${event.id}/lobby`"
                class="mb-4"
                color="primary"
                :disabled="!canAccess"
                tile
                width="250px"
                x-large
              >
                {{ $t('dashboard.actions.visit') }}
              </v-btn>
            </div>
            <div v-if="type === 'all'" class="d-flex flex-column align-center">
              <v-btn
                data-test-id="go-to-buy"
                :to="`/events/${event.id}/buy`"
                class="mb-4"
                color="primary"
                outlined
                tile
                width="250px"
                x-large
              >
                {{ $t('dashboard.actions.buy') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="0" lg="1" />
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import DateHelper from '@/helpers/date/date.helper';
import ReadMore from '@/components/read-more/ReadMore.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

export default {
  name: 'EventDetail',
  components: { ReadMore },
  props: ['event', 'theme', 'type', 'userRoles'],
  computed: {
    canAccess() {
      const isAdminOnEvent =
        PermissionsUtil.getAuthorizedRolesForEvent(this.event.id, this.userRoles).length > 0;

      return DateHelper.canAccessEvent(this.event, isAdminOnEvent);
    },
    hasMoreThanOneCoverImages() {
      return this.event.coverImages?.length > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.description {
  white-space: pre-wrap;
}

@include carousel-fix();
</style>
